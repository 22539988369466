<template>

<div class="table">
	
	<div class="table-image" v-bgimage="item.thumb"></div>

	<div class="table-name"><a :href="item.url" target="_blank">{{ item.name }}</a></div>

	<div class="table-type">{{ item.tags.join(', ') }}</div>

</div>

</template>

<script>

export default {

	props: ['item']

}

</script>

<style scoped>

.table {
	width: 100%;
	border-bottom: 2px solid #eee;
	padding: 10px 0px;
	display: flex;
	align-items: center;
}

.table:first-child {
	border-top: 2px solid #eee;
}

.table-image {
	width: 52px;
	height: 70px;
	flex-shrink: 0;
	background-color: #eee;
	background-size: cover;
	background-position: 50% 50%;
}

.is-mobile .table-image {
	width: 40px;
	height: 60px;
}

.table-name {
	padding-left: 40px;
	padding-right: 20px;
	flex-grow: 1;
	color: #1a234c;
	font-size: 18px;
	font-weight: bold;
}

.is-mobile .table-name {
	font-size: 14px;
	padding-left: 10px;
}

.table-type {
	color: #343434;
	font-style: italic;
	font-size: 14px;
	line-height: 30px;
	flex-shrink: 0;
	width: 200px;
}

.is-mobile .table-type {
	width: 64px;
	font-size: 10px;
}

</style>
